import React from 'react'
import { Link } from 'react-router-dom'
import './Header.scss'

import Logo from '../../assets/img/logo.png'

const Header = () => {
  return (
    <nav className="bg-blue-50 border-gray-200 fixed w-full top-0 start-0 z-20">
      <div className="flex flex-wrap items-center justify-between mx-auto p-4 pr-8">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={Logo} className="h-8" alt="Flowbite Logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">QR Tool</span>
        </Link>

        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
            {/* <li>
              <NavLink
                className="navbar-link block py-2 px-3 text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                to="/"
              >
                Url to QR
              </NavLink>
            </li>

            <li>
              <NavLink
                className="navbar-link block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                to="/qr-to-url"
              >
                Qr to Url
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header
