import React from 'react'
import { Tabs } from 'antd'
import SingleUrl from '../components/SingleUrl'
import ListUrl from '../components/ListUrl'
import Settings from '../components/Settings'

const items = [
  { key: 1, label: 'Single URL to QR', children: <SingleUrl /> },
  { key: 2, label: 'List URL to QR', children: <ListUrl /> },
  { key: 3, label: 'Settings', children: <Settings /> },
]

const UrlToQr = () => <Tabs defaultActiveKey="1" items={items} />

export default UrlToQr
