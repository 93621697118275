import React, { useState } from 'react'
import { Button, Select } from 'antd'
import { QRCodeSVG } from 'qrcode.react'
import { toPng, toJpeg } from 'html-to-image'
import { saveAs } from 'file-saver'
import { getSanitizedDomain } from '../../utils/helper'
import { useSelector } from 'react-redux'

const GenQR = ({ url }) => {
  const settings = useSelector((state) => state.settings.settings)
  const [format, setFormat] = useState('png')
  const [qrVisible, setQrVisible] = useState(false)
  const qrRef = React.useRef()

  const handleDownload = () => {
    if (qrRef.current) {
      setQrVisible(true)
      setTimeout(() => {
        const node = qrRef.current
        const sanitizedDomain = getSanitizedDomain(url)
        const filename = `qrcode_${sanitizedDomain}.${format}`

        if (format === 'png') {
          toPng(node)
            .then((dataUrl) => {
              saveAs(dataUrl, filename)
              setQrVisible(false)
            })
            .catch((err) => {
              console.error('Oops, something went wrong!', err)
            })
        } else if (format === 'jpg') {
          toJpeg(node, { quality: 1 })
            .then((dataUrl) => {
              saveAs(dataUrl, filename)
              setQrVisible(false)
            })
            .catch((err) => {
              console.error('Oops, something went wrong!', err)
            })
        }
      }, 0)
    }
  }
  return (
    <>
      <div className="flex items-center space-x-3 my-4">
        <div className="w-fit">
          <QRCodeSVG
            includeMargin={true}
            value={url}
            size={128}
            bgColor={settings.bgColor}
            fgColor={settings.fgColor}
            level={settings.level}
          />
        </div>

        <div ref={qrRef} className={`qr-code w-fit ${qrVisible ? '' : 'hidden'}`} data-url={url}>
          <QRCodeSVG
            includeMargin={true}
            value={url}
            size={settings.size}
            bgColor={settings.bgColor}
            fgColor={settings.fgColor}
            level={settings.level}
          />
        </div>
        <p>{url}</p>
      </div>
      <div>
        <Select defaultValue="png" onChange={(value) => setFormat(value)} style={{ width: 120, marginRight: 10 }}>
          <Select.Option value="png">PNG</Select.Option>
          <Select.Option value="jpg">JPG</Select.Option>
        </Select>
        <Button type="primary" onClick={handleDownload}>
          Download QR
        </Button>
      </div>
    </>
  )
}

export default GenQR
