import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/Header'

const Layout = () => {
  return (
    <div>
      <Header />
      <div className="max-w-screen-xl px-6 mx-auto mt-[64px] mb-5">
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
