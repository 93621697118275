import React, { useState } from 'react'
import { Button, ColorPicker, InputNumber, Select, Form, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettings } from '../../action/SettingAction'
import { QRCodeSVG } from 'qrcode.react'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const Settings = () => {
  const dispatch = useDispatch()
  const setting = useSelector((state) => state.settings.settings)
  const [errorCorrectionLevel, setErrorCorrectionLevel] = useState(setting.level || 'L')
  const [size, setSize] = useState(setting.size || 128)
  const [bgColor, setBgColor] = useState(setting.bgColor || '#ffffff')
  const [fgColor, setFgColor] = useState(setting.fgColor || '#000000')
  const [messageApi, contextHolder] = message.useMessage()

  const handleSave = () => {
    dispatch(updateSettings({ level: errorCorrectionLevel, size, bgColor, fgColor }))
    messageApi.success('Settings saved')
  }

  return (
    <div className="grid grid-cols-3 px-5">
      {contextHolder}
      <div className="flex flex-col gap-3 relative col-span-2">
        <Form
          {...layout}
          initialValues={{
            size: size,
            bgColor: bgColor,
            fgColor: fgColor,
            level: errorCorrectionLevel,
          }}
        >
          <Form.Item name="size" label="Size">
            <InputNumber min={64} max={1024} className="w-[110px]" onChange={(value) => setSize(value)} />
          </Form.Item>

          <Form.Item name="bgColor" label="Background Color">
            <ColorPicker className="w-[110px]" showText value={bgColor} onChange={(_, hex) => setBgColor(hex)} />
          </Form.Item>

          <Form.Item name="fgColor" label="Foreground Color">
            <ColorPicker className="w-[110px]" showText value={fgColor} onChange={(_, hex) => setFgColor(hex)} />
          </Form.Item>

          <Form.Item name="level" label="Error Correction Level">
            <Select onChange={(value) => setErrorCorrectionLevel(value)} style={{ width: 110 }}>
              <Select.Option value="L">L</Select.Option>
              <Select.Option value="M">M</Select.Option>
              <Select.Option value="Q">Q</Select.Option>
              <Select.Option value="H">H</Select.Option>
            </Select>
          </Form.Item>
        </Form>

        <Button type="primary" className="w-fit self-center" onClick={handleSave}>
          Save
        </Button>
      </div>

      <div>
        <h2>Preview:</h2>
        <div className="w-fit">
          <QRCodeSVG
            includeMargin={true}
            value="https://picturesofpeoplescanningqrcodes.tumblr.com"
            size={size > 256 ? 256 : size}
            level={errorCorrectionLevel}
            bgColor={bgColor}
            fgColor={fgColor}
          />
        </div>
      </div>
    </div>
  )
}

export default Settings
