export const checkValidUrl = (string) => {
  const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/i
  return regex.test(string)
}

export const getSanitizedDomain = (url) => {
  try {
    const { hostname, pathname, search } = new URL(url.includes('://') ? url : 'http://' + url)
    const completeUrl = `${hostname}${pathname}${search}`
    const domainWithoutWww = completeUrl.replace(/^www\./i, '')
    return domainWithoutWww.replace(/[^a-z0-9]/gi, '-').toLowerCase()
  } catch (_) {
    return ''
  }
}
