import React, { useState, useRef } from 'react'
import GenQR from '../GenQR'
import { UploadOutlined } from '@ant-design/icons'
import { checkValidUrl } from '../../utils/helper'
import { Button, Select } from 'antd'
import JSZip from 'jszip'
import { getSanitizedDomain } from '../../utils/helper'
import { toPng } from 'html-to-image'
import { saveAs } from 'file-saver'

const ListUrl = () => {
  const [fileContent, setFileContent] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [format, setFormat] = useState('png')
  const inputRef = useRef(null)

  const handleFileChosen = (file) => {
    if (!file) return
    const fileReader = new FileReader()
    fileReader.onloadend = handleFileRead
    fileReader.readAsText(file)
  }

  const handleFileRead = (event) => {
    const content = event.target.result
    const lines = content
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line)
    setFileContent(lines)
    if (inputRef.current) inputRef.current.value = null
  }

  const handleClickUpload = () => {
    inputRef.current.click()
  }

  const handleDrop = (event) => {
    event.preventDefault()
    setIsDragging(false)
    handleFileChosen(event.dataTransfer.files[0])
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDownloadAll = () => {
    const qrNodes = document.querySelectorAll('.qr-code')
    qrNodes.forEach((node) => {
      node.classList.remove('hidden')
    })
    const zip = new JSZip()
    const imgFolder = zip.folder('images')
    const promises = []

    qrNodes.forEach((node, index) => {
      const url = node.getAttribute('data-url')
      const sanitizedDomain = getSanitizedDomain(url)
      const filename = `qrcode_${sanitizedDomain}.${format}`

      const promise = toPng(node)
        .then((dataUrl) => {
          const imgData = dataUrl.split(',')[1]
          imgFolder.file(filename, imgData, { base64: true })
        })
        .catch((err) => {
          console.error('Oops, something went wrong!', err)
        })

      promises.push(promise)
    })

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'qrcodes.zip')
        qrNodes.forEach((node) => {
          node.classList.add('hidden')
        })
      })
    })
  }

  return (
    <div className="grid sm:grid-cols-2 grid-cols-1 min-h-[50vh]">
      <div className="sm:border-r px-5 h-full">
        <input
          type="file"
          ref={inputRef}
          className="hidden"
          accept=".txt"
          onChange={(e) => handleFileChosen(e.target.files[0])}
        />

        <div
          className={`flex flex-col justify-center items-center gap-2 w-full h-[120px] px-5 rounded-lg cursor-pointer border hover:border-blue-400 ${isDragging ? 'border-solid border-blue-500' : 'border-dashed border-blue-200'}`}
          onClick={handleClickUpload}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
        >
          <UploadOutlined className={`text-4xl mx-auto ${isDragging ? 'text-blue-500' : 'text-blue-200'}`} />
          {isDragging ? 'Drop here' : 'Click to upload file'}
        </div>

        <div>
          {fileContent.map((line, index) => (
            <p key={index} className={`${checkValidUrl(line) ? '' : 'text-red-600'}`}>
              {checkValidUrl(line) ? line : `Invalid URL: ${line}`}
            </p>
          ))}
          {fileContent.length > 0 && (
            <Button type="text" danger onClick={() => setFileContent([])}>
              Clear
            </Button>
          )}
        </div>
      </div>

      <div className="px-5">
        <h3>Result:</h3>
        {fileContent.length > 1 && (
          <div>
            <Select defaultValue="png" onChange={(value) => setFormat(value)} style={{ width: 120, marginRight: 10 }}>
              <Select.Option value="png">PNG</Select.Option>
              <Select.Option value="jpg">JPG</Select.Option>
            </Select>

            <button
              className="transition-all rounded-md px-[15px] py-1 text-white bg-green-500 hover:bg-green-600"
              onClick={handleDownloadAll}
            >
              Download All
            </button>
          </div>
        )}
        <div>
          {fileContent.map((line, index) => (
            <div key={index}>
              {line && checkValidUrl(line) && <GenQR url={line} />}
              {line && !checkValidUrl(line) && <p className="text-red-600 mt-5">Invalid URL: {line}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ListUrl
