import { Input } from 'antd'
import React from 'react'
import { checkValidUrl } from '../../utils/helper'
import GenQR from '../GenQR'

const SingleUrl = () => {
  const [input, setInput] = React.useState('')

  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-800 dark:text-white">Url to QR</h1>
      <Input
        placeholder="Enter URL"
        className="mt-3 w-[300px]"
        onChange={(e) => setInput(e.target.value)}
        value={input}
      />

      {input && checkValidUrl(input) && <GenQR url={input} />}

      {input && !checkValidUrl(input) && <p className="text-red-600 mt-1">Invalid URL</p>}
    </>
  )
}

export default SingleUrl
