const initialState = {
  settings: {
    level: 'L',
    size: 128,
    bgColor: '#ffffff',
    fgColor: '#000000',
  },
}

const getInitialState = () => {
  try {
    const savedState = localStorage.getItem('qrtoolState')
    if (savedState) {
      return JSON.parse(savedState)
    }
  } catch (error) {
    console.error('Error parsing state from localStorage:', error)
  }
  return initialState
}

const SettingReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case 'SET_SETTINGS':
      const newState = {
        ...state,
        settings: action.settings, // Chỉ cập nhật phần settings
      }
      try {
        localStorage.setItem('qrtoolState', JSON.stringify(newState))
      } catch (error) {
        console.error('Error saving state to localStorage:', error)
      }
      return newState
    default:
      return state
  }
}

export default SettingReducer
