import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './pages/Layout'
import UrlToQr from './pages/UrlToQr'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<UrlToQr />} />
          {/* <Route path="qr-to-url" element={<QrToUrl />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
